<template>
  <div class="row" v-if="Object.keys(hub).length !== 0">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-weight-bold">Hub Name</label>
                <p>{{ hub.name }}</p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-weight-bold">Phone</label>
                <p>{{ hub.phone }}</p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-weight-bold">Address</label>
                <p>{{ hub.address }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <a-transfer
            :data-source="coa_masters"
            show-search
            :target-keys="hub_coa_masters"
            :render="item => item.title"
            @change="coaMasterChange"
            @search="handleSearch"
          />

          <a-button v-if="hub_coa_masters.length > 0" class="btn btn-success waves-effect waves-light mt-2" :loading="hubCoaMasterBtn" @click.prevent="save"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Save Change</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'hub_coa_assign',
  data() {
    return {
      hub: {},
      hub_coa_masters: [],
      coa_masters: [],
      loader: false,
      flag: false,
      loading: false,
      hubCoaMasterBtn: false,
    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    getProducts() {
      this.loading = true
      const hubId = this.$route.params.hub_id
      apiClient.get('api/hub/chart-of-accounts/' + hubId)
        .then(response => {
          this.loading = false
          if (!response.data.error) {
            const data = response.data
            this.hub = data.hub
            this.coa_masters = data.coa_masters
            this.hub_coa_masters = data.hub_coa_masters
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    coaMasterChange(hubCoaMasters, direction, moveKeys) {
      this.hub_coa_masters = hubCoaMasters
    },
    handleSearch(dir, value) {
      console.log('search:', dir, value)
    },
    save() {
      this.hubCoaMasterBtn = true
      const hubId = this.$route.params.hub_id
      apiClient.post('api/hub/chart-of-accounts/store/' + hubId, this.hub_coa_masters)
        .then(response => {
          this.hubCoaMasterBtn = false
          if (!response.data.error) {
            this.$notification.success({
              message: response.data.message,
            })
            this.$router.push({ name: 'hubChartOfAccount' })
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
<style>
.ant-transfer-list{
  width: 40% !important;
  height: 320px !important;
}
</style>
